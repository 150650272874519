<template>
  <Sending />
  <div class="container">
    <div class="msg">
      <div class="msg__title">お問い合わせありがとうございました</div>
      <div class="msg__text">
        お問い合わせ内容確認後、担当者より改めてご連絡させていただきます。<br class="pcOnly" />
        生地見本付きリーフレットをご希望のお客様は到着まで今しばらくお待ちください。<br class="pcOnly" />
        引き続きよろしくお願い致します。
      </div>
      <div class="msg__note">
        自動返信メールが届かない方はメールアドレスが間違っている可能性がありますので、お手数ですが再度お問い合わせいただくか、<a href="mailto:customer@ms-fabric.com">customer@ms-fabric.com</a
        >より直接ご連絡ください。
      </div>
    </div>
  </div>
</template>

<script>
import Sending from '../components/Sending.vue'

export default {
  components: { Sending },
}
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 120px - 300px);
  margin-bottom: -5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 80px - 150px);
  }
}
.msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    font-size: 2.5rem;
    margin-bottom: 5rem;
  }

  &__text {
    font-size: 14px;
    text-align: center;
    line-height: 1.8;

    @media screen and (max-width: 425px) {
      text-align: left;
    }
  }

  &__note {
    background-color: #333;
    font-size: 12px;
    padding: 1.5rem 2rem;
    width: 400px;
    margin-top: 3rem;
    text-align: left;
    line-height: 1.6;

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
}
</style>
